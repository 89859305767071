/**{
    color: #3f3f3f;
}*/

.carousel-item {
    height: 55vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.navbar-brand img{
    width: 332px;
    height: 156px;
}

.navbar{
    padding: 0;
    background-color: #bcbaa7;
}

footer{
    padding: 20px 0 20px 0;
    background-color: #006532;
}

footer *{
    font-size: 13px;
    color: #ffffff;
}

footer a{
    color: #ffffff;
    margin-right: 15px;
}

footer a:hover{
    color: #ffffff;
}

.subnav a,
a.nav-link{
    color: #3f3f3f;
    font-weight: bold;
}

.subnav a:hover,
a.nav-link:hover{
    color: #3f3f3f;
    text-decoration: underline;
}

.subnav {
    border-top: 1px solid #3f3f3f;
    background: #006532;
    padding: 15px 0px;
}

.subnav a {
    margin-right: 20px;
}

.subnav a.active {
    text-decoration: underline;
}


a.nav-link-portal{
    color: #3f3f3f;
    font-size: 19px;
    margin-right: 25px;
}

a.nav-link-portal.active{
    text-decoration: underline;
}

.desktopnav{
    text-align: right;
    padding-top: 93px;
}

.teaser{
    background-color: #bcbaa7;
}

.teasertext{
    padding: 10px;
}

.teasertext a{
    text-decoration: none;
    color: #3f3f3f;
    font-size: 26px;
    margin-bottom: 10px;
    display: block;
}

.isClickable{
    cursor: pointer;
}

.mb-25{
    margin-bottom: 35px;
}

.gallery .popup-link{
    margin-bottom: 30px;
    display: block;
}

.navbar-toggler {
    position: absolute;
    right: 0;
}


.slick-prev {
    left: 30px;
    z-index: 5;
}

.slick-next {
    right: 30px;
    z-index: 5;
}

.slick-dots {
    bottom: 20px;
}

#routemap {
    width: 100%;
    height: 500px;
}

#strataddr{
    width: 100%!important;
}

#mapdirections table{
    width: 100%!important;
}

.subbutton {
    background-color: #bcbaa7;
    border-color: #bcbaa7;
}

.subbutton:hover {
    color: #ffffff;
    background-color: #bcbaa7;
    border-color: #bcbaa7;
}