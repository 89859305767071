body{
    background: #ececec;
}

.verkaufteaser{
    margin-bottom: 35px;
}

.galleryimage{
    margin-bottom: 35px;
}

.navbar{
    background-color: #006532;
}

.navbar a,
.navbar a:hover{
    color: #ffffff;
}

.navbar .nav-item.active {
    text-decoration: underline;
}

a:hover,
a {
    color: #006532;
}

.btn-primary:hover,
.btn-primary {
    background-color: #006532;
    border-color: #006532;
}

.thumbnail-teaser{
    background: #ffffff;
    padding: 5px;
    border: 1px solid #006532;
}

.thumbnail-teaser img{
    margin-bottom: 10px;
}

.view {
    position: relative;
    overflow: hidden;
}

.view .masktext,
.view .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
}

.flex-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.white-text,
.white-text:hover,
.rgba-green-strong {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 29px;
}

.rgba-green-strong:hover {
    background-color: rgba(0,101,50,.7);
}

.overlay .mask {
    opacity: 0;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.overlay .mask:hover {
    opacity: 1;
}

.lngswitch{
    position: absolute;
    top: 10px;
    right: 10px;
}

.lngswitch ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.lngswitch ul li{
    margin: 0;
    padding: 0;
    list-style: none;
}

.navbar-toggler {
    top: 5px;
    color: #ffffff;
}

.navbar-nav .nav-link {
    padding-left: 16px;
}

/* mobile */
@media only screen and (max-width: 767px) {
    .h1, h1 {
        font-size: 2rem;
    }
}